import React from "react"
// import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="DIzjava o pristupačnosti" />
    <main id="main">
    <h1>Izjava o pristupačnosti</h1>
    <p>Županijska lučka uprava Krk (u daljnjem tekstu ŽLUK) nastoje svoje mrežne stranice (<a href="https://baska.zluk.hr">baska.zluk.hr</a>) učiniti pristupačnima u skladu sa&nbsp;<a href="http://sredisnjikatalogrh.gov.hr/cadial/searchdoc.php?action=search&amp;lang=hr&amp;query=pristupa%C4%8Dnost&amp;searchText=on&amp;searchTitle=on&amp;resultdetails=basic&amp;bid=%2bELrvTWXEzq35H83vcz6kg%3d%3d&amp;annotate=on">Zakonom o pristupačnosti mrežnih stranica i programskih rje&scaron;enja za pokretne uređaje tijela javnog sektora Republike Hrvatske</a>&nbsp;(&ldquo;Narodne novine&rdquo;, broj 17/19; dalje u tekstu: Zakon) kojim se prenosi&nbsp;<a href="https://eur-lex.europa.eu/legal-content/HR/TXT/?uri=OJ%3AL%3A2016%3A327%3ATOC">Direktiva (EU) 2016/2102 Europskog parlamenta i Vijeća od 26. listopada 2016. o pristupačnosti internetskih stranica i mobilnih aplikacija tijela javnog sektora</a>&nbsp;(SL EU, L 327, 2. 12. 2016.).<br />Ova Izjava o pristupačnosti primjenjuje se na mrežno sjedi&scaron;te ŽLUK koje se nalazi na adresi&nbsp;<a href="https://baska.zluk.hr/">https://baska.zluk.hr</a></p>
<p><strong>Stupanj usklađenosti</strong></p>
<p>Mrežno sjedi&scaron;te&nbsp;<a href="https://baska.zluk.hr/">baska.zluk.hr</a>&nbsp;djelomično je usklađeno sa Zakonom zbog neusklađenosti koje su navedene u nastavku.</p>
<p><strong>Nepristupačan sadržaj</strong></p>
<p>Sadržaj naveden u nastavku nepristupačan je iz razloga:</p>
<ul>
<li>Korisnicima nije omogućena jednostavna promjena veličine teksta, ni kontrasta koji na pojedinim dijelovima sadržaja nije optimalno pode&scaron;en</li>
<li>Ne postoji opcija uključivanja fonta za osobe s disleksijom</li>
<li>Fokus tj. položaj pokazivača na ekranu nije vidljiv i čitljiv čitaču ekrana niti je osigurano optimalno kori&scaron;tenje čitača zaslona za desktop i mobilne platforme s obzirom na to da nisu kori&scaron;teni za to potrebni specifični strukturalni elementi iz HTML5 standarda</li>
</ul>
<p><strong>Podizanje razine pristupačnosti</strong></p>
<p>ŽLUK poduzima aktivnosti kojima će nastojati postići usklađenost sa Zakonom.</p>
<p><strong>Priprema ove izjave o pristupačnosti</strong></p>
<p>Ova je izjava sastavljena 21. rujna 2020. godine, prema Predlo&scaron;ku izjave o pristupačnosti koji je u skladu s Direktivom (EU) 2016/2102 Europskog parlamenta i Vijeća o pristupačnosti internetskih stranica i mobilnih aplikacija tijela javnog sektora, a utvrđen je&nbsp;<a href="https://eur-lex.europa.eu/legal-content/HR/TXT/HTML/?uri=CELEX:32018D1523&amp;qid=1590482508144&amp;from=HR">Provedbenom odlukom Komisije (EU) 2018/1523</a>.<br />Procjena usklađenosti sa zahtjevima pristupačnosti rezultat je samoprocjene.<br />Izjava je zadnji put preispitana&nbsp;28. listopada 2020.&nbsp;<br />ŽLUK će redovito revidirati ovu izjavu po otklanjanju razloga zbog kojih su pojedini sadržaji bili nepristupačni.</p>
<p><strong>Povratne informacije i podaci za kontakt</strong></p>
<p>Molimo korisnike ovih mrežnih stranica ako primijete neusklađen sadržaj, koji nije obuhvaćen ovom izjavom, da o tome obavijeste ŽLUK.<br />Sve upite vezane uz pristupačnost internetske stranice&nbsp;<a href="https://baska.zluk.hr/">https://baska.zluk.hr/</a>&nbsp;korisnici mogu uputiti:</p>
<ul>
<li>elektroničkom po&scaron;tom:&nbsp;<span id="7fcabfe4911afec8b5b8fd80ef54fc7b1cfad1d3"><a href="mailto:ana.jelovac@zluk.hr">ana.jelovac@zluk.hr</a></span></li>
<li>telefonom: +385 51 220 165</li>
<li>po&scaron;tom: Županijska lučka uprava Krk,Trg bana Josipa Jelačića 5, 51500 Krk, Hrvatska</li>
</ul>
<p><strong>Postupak praćenja provedbe propisa</strong></p>
<p>Povjerenik za informiranje Republike Hrvatske je tijelo nadležno za praćenje usklađenosti mrežnih stranica i programskih rje&scaron;enja za pokretne uređaje tijela javnog sektora sa zahtjevima pristupačnosti kao i za nadzor nad provedbom Zakona.<br />U slučaju nezadovoljavajućih odgovora na obavijest ili zahtjev za povratne informacije o pristupačnosti ovih mrežnih stranica, korisnici se mogu obratiti Povjereniku za informiranje putem elektroničke po&scaron;te:&nbsp;<a href="mailto:pristupacnost@pristupinfo.hr">pristupacnost@pristupinfo.hr</a>&nbsp;ili na&nbsp;<a href="mailto:ppi@pristupinfo.hr">ppi@pristupinfo.hr</a>.</p>
<p>&nbsp;</p>
</main>

  </Layout>
)

export default IndexPage
